import { Field, Form, Formik } from 'formik';
import { React } from 'react';
import '../css/contact.css';
import * as Yup from 'yup';

function Contact() {
	const ValidationSchema = Yup.object().shape({
		name: Yup.string().required('Please insert Name'),
		email: Yup.string().email('Invalid email').required('Please insert email'),
		subject: Yup.string().required('Please state your subject'),
		message: Yup.string().required('Please insert message')
	});

	const handleSubmit = (values) => {
		window.location.replace(
			`mailto:james@highground.life?subject=${values.subject}&body=${values.message}%0A%0AName - ${values.name}%0AEmail - ${values.email}`
		);
	};

	return (
		<section id="contact" className="section">
			<div className="contact-container">
				<div className="section-header">
					{/* eslint-disable-next-line react/no-unescaped-entities */}
					<h2 className="section-title">LET'S WORK TOGETHER.</h2>
				</div>
				<div className="row contact-form">
					<div className="col-8 offset-2">
						<div className="contact-block">
							<Formik
								id="contactForm"
								initialValues={{
									name: '',
									email: '',
									subject: '',
									message: ''
								}}
								onSubmit={handleSubmit}
								validationSchema={ValidationSchema}
							>
								{({ errors, touched }) => (
									<Form>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group pb-3">
													<Field
														type="text"
														className="form-control"
														id="name"
														name="name"
														placeholder="Your Name"
													/>
													{errors.name && touched.name && (
														<div className="formValidationErrorMessage">{errors.name}</div>
													)}
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group pb-3">
													<Field
														type="email"
														placeholder="Your Email"
														id="email"
														className="form-control"
														name="email"
													/>
													{errors.email && touched.email && (
														<div className="formValidationErrorMessage">{errors.email}</div>
													)}
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group pb-3">
													<Field
														type="text"
														id="subject"
														name="subject"
														placeholder="Subject"
														className="form-control"
													/>
													{errors.subject && touched.subject && (
														<div className="formValidationErrorMessage">{errors.subject}</div>
													)}
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group pb-3">
													<Field
														as="textarea"
														className="form-control"
														name="message"
														id="message"
														placeholder="Your Message"
														rows="7"
													/>
													{errors.message && touched.message && (
														<div className="formValidationErrorMessage">{errors.message}</div>
													)}
												</div>
												<button type="submit" className="btn btn-common btn-effect">
													Send Message
												</button>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Contact;
